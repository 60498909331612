import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import { withTranslation } from "react-i18next";
// import { authenticationService } from './_services';
import axios from 'axios';

class App extends Component {
  state = {}

  componentDidMount() {

    this.onRouteChanged();
  }

  render () {
    let navbarComponent = !this.state.isFullPageLayout ? '' : '';
    let sidebarComponent = !this.state.isFullPageLayout ? '' : '';
    let footerComponent = !this.state.isFullPageLayout ? '' : '';
    return (
      // <div className="container-scroller">
      //   <div className="container-fluid page-body-wrapper">
      //     <div className="main-panel">
      //       <div className="content-wrapper">
      //         <AppRoutes/>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div class="wrapper">
        <AppRoutes/>
      </div>
      // <AppRoutes/>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const { i18n } = this.props;
    // const body = document.querySelector('body');
    // if(this.props.location.pathname === '/layout/RtlLayout') {
    //   body.classList.add('rtl');
    //   i18n.changeLanguage('ar');
    // }
    // else {
    //   body.classList.remove('rtl')
    //   i18n.changeLanguage('en');
    // }
    // window.scrollTo(0, 0);
    // const fullPageLayoutRoutes = ['/register', '/my_dragons', '/login', '/login_old', '/main', '/register_old', '/nest', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    // for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
    //   if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
    //     this.setState({
    //       isFullPageLayout: true
    //     })
    //     document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
    //     break;
    //   } else {
    //     this.setState({
    //       isFullPageLayout: false
    //     })
    //     document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
    //   }
    // }
  }

}

export default withTranslation()(withRouter(App));
