import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ReactGA from 'react-ga';

// import Nest from './user-pages/Nest';

import Spinner from '../app/shared/Spinner';

const Mdi = lazy(() => import('./icons/Mdi'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Dragon = lazy(() => import('./dragon_preview/Dragon'));
const Battle = lazy(() => import('./user-pages/Battle'));
const BattleDragons = lazy(() => import('./dashboard/BattleDragons'));
const Login = lazy(() => import('./user-pages/LoginOld'));
const MainOld = lazy(() => import('./user-pages/MainOld'));
const RegisterOld = lazy(() => import('./user-pages/RegisterOld'));
const MyDragons = lazy(() => import('./user-pages/MyDragons'));
const Nest = lazy(() => import('./user-pages/Nest'));
const Adopt = lazy(() => import('./user-pages/Adopt'));
const Expeditions = lazy(() => import('./user-pages/Expeditions'));
const Trainings = lazy(() => import('./user-pages/Trainings'));
const Fight = lazy(() => import('./user-pages/Fight'));
const FightV2 = lazy(() => import('./user-pages/FightV2'));
const Equipment = lazy(() => import('./user-pages/Equipment'));
const Settings = lazy(() => import('./user-pages/Settings'));
const Profile = lazy(() => import('./user-pages/Profile'));
const Actions = lazy(() => import('./user-pages/Actions'));
const Market = lazy(() => import('./user-pages/Market'));
const Prototype = lazy(() => import('./user-pages/Prototype'));
const Prototype2 = lazy(() => import('./user-pages/Prototype2'));
const DevilsCircle = lazy(() => import('./user-pages/DevilsCircle'));

class AppRoutes extends Component {  
  componentDidMount() {
    this.trackPageView(); // Call the trackPageView function on initial mount
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.trackPageView(); // Call the trackPageView function when location changes
    }
  }

  trackPageView = () => {
    if (process.env.NODE_ENV === 'production') {

      // ReactGA.pageview(this.props.location.pathname + this.props.location.search);
    }
  };

  render () {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route path="/login" component={ Login } />
          <Route path="/adopt" component={ Adopt } />
          <Route path="/main" component={ MainOld } />
          <Route path="/register" component={ RegisterOld } />
          {/* <Route path="/register_old" component={ RegisterOld } /> */}
          <Route path="/my_dragons" component={ MyDragons } />
          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />
          {/* <Route path="/dragon" component={ Dragon } /> */}
          <Route path="/dragon/:name" component={ Dragon } />
          <Route path="/nest/:name" component={ Nest } />
          <Route path="/owner/:name" component={ Profile } />
          <Route path="/expeditions" component={ Expeditions } />
          <Route path="/trainings" component={ Trainings } />
          <Route path="/market" component={ Market } />
          {/* <Route path="/fight" component={ Fight } /> */}
          <Route path="/fight" component={ FightV2 } />

          <Route path="/equipment" component={ Equipment } />
          <Route path="/settings" component={ Settings } />
          <Route path="/actions" component={ Actions } />
          <Route path="/prototype" component={ Prototype2 } />
          <Route path="/devils_circle" component={ DevilsCircle } />

          <Redirect to="/main" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
